<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"/>        
              <b-button variant="primary" :to="{ name: 'instructorCreate' }">
                <span class="text-nowrap">{{ $t("Create Trainer") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
      :per-page="perPage"
        :current-page="currentPage"
        :items="trainers_filter"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :empty-filtered-text="$t('There are no records matching your request!')"
        :sort-desc.sync="isSortDirDesc"
      >
      <template #cell(resendinvite)="data">
              <template v-if="isMobile === true">
                <b-button
                  size="sm"
                  v-if="!data.item.is_confirm"
                  variant="primary"
                  @click="
                    reSendInvite(
                      data.item.company_branch_person_role_id,
                      data.item.person_id
                    )
                  "
                >
                  {{ $t("Re-Send Invite") }}
                </b-button>
              </template>
              <template v-if="isMobile === false">
                <b-button
                  size="sm"
                  v-if="!data.item.is_confirm"
                  variant="primary"
                  @click="
                    reSendInvite(
                      data.item.company_branch_person_role_id,
                      data.item.person_id
                    )
                  "
                >
                  {{ $t("Re-Send Invite") }}
                </b-button>
              </template>
            </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
      
          <small v-if="$i18n.locale === 'tr'" class="mt-1 strong"
            >{{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
              currentPage * perPage
            }}
            arası ({{ currentPage }}.sayfa) gösteriliyor
          </small>
          <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage*1}}-{{currentPage*perPage}} (page {{currentPage}}) of {{totalRows}} records</small>
          <!-- Pagination -->
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius
              0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormSelect,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,

    vSelect,
  },

  data() {
    return {
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,

      filterOn: [],

      locale: this.$i18n.locale,

         tableColumns: [
        { key: "full_name", label: this.$t("FULL NAME"), sortable: true },
        { key: "email", label: this.$t("TRAINER EMAIL"), sortable: true },
        {
          key: "invite_created_date",
          label: this.$t("CREATED DATE"),
          sortable: true,
        },
        {
          key: "resendinvite",
          label: this.$t("RE-SEND INVITE"),
          sortable: true,
        }],

      selected: "all",
      options: [
        { text: this.$t("All Customers"), value: "all" },
        { text: this.$t("Active Customers"), value: "aktif" },
        { text: this.$t("Deactive Customers"), value: "deaktif" },
      
      ],
      companyBranchId: "",

      is_active:0,
      trainers:[],
      trainers_filter:[],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.trainers_filter
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    trainers_filter(val) {
     this.totalRows = val.length;
    },
  },
  methods: {

     getTrainerList(company_branch_id, is_active) {
      this.$database.TrainerService.get_trainers(company_branch_id, is_active
      ).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          res.Result.map((el) => {

              let instructors={
                  trainer_id:el.TrainerId,
                  company_branch_id:el.CompanyBranchId,
                  company_branch_person_role_id:el.CompanyBranchPersonRoleId,
                  person_id:el.PersonId,
                  birthday:el.Birthday,
                  full_name: el.FirstName,
                  email:el.Email,
                  gender:el.Gender,
                  phone_number:el.PhoneNumber,
                  invite_created_date: this.dateFormat(el.CreatedDate),
                  invite_resend_invite_date: this.dateFormat(el.ResendInviteDate),
                  is_active: el.IsActive
              }

            if (el.IsConfirm === false || el.IsConfirm === 0) 
            {
              this.trainers.push(instructors);
            }
          });
           this.trainers_filter = this.trainers;
        }
      });
    },

     reSendInvite(company_branch_person_role_id, person_id) {
        console.log({"company_branch_person_role_id":company_branch_person_role_id},
                    {"person_id":person_id});
      this.$database.AccountService.re_send_invite({
        CompanyBranchPersonRoleId: company_branch_person_role_id,
        PersonId: person_id,
      }).then((res) => {
        // console.log(res);
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          this.$functions.Messages.success("Invitation Sent");
        } else {
          this.$functions.Messages.error("Action failed!");
        }
      });
    },

    onFiltered(filteredItems)
    {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    // this.$route.meta.breadcrumb[0].title = 'newTitle'
    this.companyBranchId = this.$store.getters["auth/userInfo"].company_branch_id
   // this.getCustomerList();

    this.getTrainerList(this.companyBranchId, this.is_active)


  },
};
</script>
<style lang="scss" scoped src="@/styles/scss/customerListScoped.scss"></style>

