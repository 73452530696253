<template>
  <div>
    <b-card no-body class="mb-0">
      <b-tabs ref="tabRef" content-class="pt-1 " fill>
        <b-tab :title="$t('Trainers')">
          <Trainers></Trainers>              
        </b-tab>
        <b-tab :title="$t('Invited Trainers')">
            <InvitedTrainerList></InvitedTrainerList>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Trainers  from '@/components/TrainerComponents/TrainerList.vue'
import InvitedTrainerList from '@/components/TrainerComponents/InvitedTrainerList.vue'
import Ripple from "vue-ripple-directive";
export default {
  name: "studio_instructor_list",
  components: {
    Trainers,
    InvitedTrainerList,
    BFormSelect,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {  
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
    this.companyBranchId =
      this.$store.getters["auth/userInfo"].company_branch_id;
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/studioInstructorList.scss" scoped></style>